.searchPresenter-container {
    background-color: white;
    font-weight: 500;
    border-radius: $border-radius;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
    padding: 3rem $content-padding-x;

    @include media-breakpoint-up(md) {
        padding: 4rem 7.8rem;
	    max-width: 45rem;
	    margin: auto;
    }

    & p {
        margin-bottom: 0;
    }
}

.searchPresenter-heading {
    font-size: 2.1em;
    font-weight: 700;
    text-align: center;
    letter-spacing: -0.3px;
    padding-bottom: 3rem;
}

.searchPresenter-search,
.searchPresenter-transporter {
    padding-bottom: 0.8rem;
    font-size: 1.25rem;
    font-weight: 400;
}

// .searchPresenter-search {
//     font-family: $input-font-family;
// }

.searchPresenter-transporter {
    margin-bottom: 1.5rem;
}

.searchPresenter-submitButton {
	margin: 0 auto;
    background: $primary-color;
    color: white;
    font-weight: 500;
    font-size: 1.45rem;
    padding: 0.8rem 5.5rem;
    border-radius: $border-radius;
    border: none;
    display: block;
    letter-spacing: -0.5px;
    margin-bottom: 5rem;

    &:hover {
        background-color: lighten($primary-color, 15%);
        cursor: pointer;
    }
}

.searchPresenter-advancedLink {
    @include link($text-color);
    text-decoration: none;
    display: block;
    margin: 0 auto 0.5rem auto;
    font-weight: 400;
    text-align: center;

    &:hover,
    &:active {
        text-decoration: underline;
    }
}

.searchPresenter-aboutLink {
    @include link($text-color);
    text-decoration: none;
    display: block;
    margin: 0 auto;
    font-weight: 400;
    text-align: center;

    &:hover,
    &:active {
        text-decoration: underline;
    }
}
