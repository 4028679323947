.modalPresenter-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    z-index: 5;

    &.modalPresenter-active {
        display: block;
    }
}

.modalPresenter-wrapper {
    position: fixed;
    top: -100000px;
    left: -100000px;
    display: block;
    max-width: 800px;
    max-height: 800px;
    z-index: 6;
    transform-origin: 50% 100%;

    &.modalPresenter-active {
        top: 0;
        left: 0;
        margin: 0;
    }
}

.modalPresenter-contentWrapper {
    position: relative;
}

.modalPresenter-header {
    font-size: 1.25rem;
    border-bottom: $background-color 2px solid;
}
.modalPresenter-content {
    width: 100%;
    height: 100%;
    background-color: $background-color;
    border-radius: 0.5rem;
    overflow: hidden;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}
.modalPresenter-close {
    position: absolute;
    left: calc(50% - 13px);
    top: -50px;
    border: none;
    background: none;
    font-size: 42px;
    line-height: 1;
    color: white;
    padding: 0;

    &:focus,&:active {
        outline: none;
    }
}
