.glyphIconInputView-wrapper {
    position: relative;
}

.glyphIconInputView-label {

}

.glyphIconInputView-input {
    display: block;
    width: 100%;
    border: $input-border-thickness solid $input-border-color;
    color: $input-color;;
    padding: $input-padding-y $input-padding-x $input-padding-y #{$input-padding-x + 1.6rem};
    background-color: $input-bg;
    line-height: $input-line-height;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    border-radius: $input-border-radius;
    font: inherit;

    .iconRight > & {
        padding: $input-padding-y #{$input-padding-x + 1.6rem} $input-padding-y $input-padding-x;
    }

    &:focus {
        color: $input-color-focus;
        background-color: $input-bg-focus;
        border-color: $input-border-focus;
        outline: none;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba($input-box-shadow-focus, 0.8);
    }
}

.glyphIconInputView-icon {
    display: block;
    position: absolute;
    left: 1.2rem;
    top: 0;
    bottom: 0;
    margin: auto;
    height: #{$input-line-height * 1em};
    font-size: #{$input-line-height * 1em};
    line-height: 1;

    .iconRight > & {
        left: initial;
        right: 1.2rem;
    }

    & > span {
        vertical-align: middle;
    }
}

.glyphIconInputView-feedback {

}
