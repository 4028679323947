.mainPresenter-logoBottom {
    padding: 3rem;
    margin: 0 auto;
    width: 100%;
    max-width: 500px;
    opacity: 0.05;
}

.mainPresenter-header {
    text-align: center;
    margin: 0 auto;
}

.mainPresenter-search {
    @include media-breakpoint-up(lg) {
        // padding: 0rem $xl-content-padding-x;
        max-width: 800px;
        margin: 0 auto;
        padding: 0 $content-padding-x;
    }
}
