.inputView-wrapper {
    position: relative;
}

.inputView-label {

}

.inputView-input {
    display: block;
    width: 100%;
    border: $input-border-thickness solid $input-border-color;
    color: $input-color;;
    padding: $input-padding-x $input-padding-y;
    background-color: $input-bg;
    line-height: $input-line-height;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    border-radius: $input-border-radius;
    font: inherit;
    letter-spacing: inherit;

    &:focus {
        color: $input-color-focus;
        background-color: $input-bg-focus;
        border-color: $input-border-focus;
        outline: none;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba($input-box-shadow-focus, 0.8);
    }
}

.inputView-feedback {

}
