$primary-color: #00cd93;
$alternate-color: #3eade1;
$alternate-color-light: #eaf8ff;

$background-color: #f0f0f0;
$separator-color: #dddddd;
$content-background-color: #ffffff;

$text-color: #000000;
$disabled-color: #999999;

$text-font-family: 'Roboto';
$number-font-family: 'RobotoMono';
$input-font-family: 'RobotoMono';

$border-radius: 4px;

$content-padding-x: 1.5rem;
$xl-content-padding-x: 15rem;
$event-padding-equal-x: 4.8rem;

// Override defaults
// $input-border-color: $primary-color;
$input-border-radius: $border-radius;
$input-border-thickness: 2px;
$input-padding-y: 0.8rem;
$input-padding-x: 0.8rem;
