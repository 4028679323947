.headerPresenter-container {
    margin: 2rem auto;
    text-align: center;
    
    @include media-breakpoint-up(md) {
        margin: 4rem auto;
    }
}

.headerPresenter-text {
    opacity: 0.5;
    font-size: 0.8rem;
    text-transform: uppercase;
    padding: 0;
    margin: 0 auto;
    font-weight: 700;
    padding-right: 8px;
    letter-spacing: 0.6px;
}

.headerPresenter-image {
    width: 125px;
    height: auto;
    border: none;
    margin-left: -28px;
    margin-top: -6px;

    &:hover {
        cursor: pointer;
    }
}
