.logoPresenter-image {
	width: 100%;
	height: 100%;
	background-image: url(img/tim-logo.svg);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right;

	@include media-breakpoint-up(md) {
		background-position: center;
	}

	display: inline-block;
	.tim &,
	.tim_test & {
		margin-top: -11px;
	}

	.bama & {
		max-width: 125px;
		margin-top: -6px;
	}

	.bts & {
		max-height: 60px;
	}
}