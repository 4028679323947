// Bootstrap
$font-size-root-value: 14;
$font-size-root: #{$font-size-root-value}px;
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/reboot';

// Font Awesome
$fa-font-path: 'fonts';
@import 'font-awesome/scss/variables';
@import 'font-awesome/scss/core';
@import 'font-awesome/scss/icons';
@import 'font-awesome/scss/path';
@import 'font-awesome/scss/mixins';

// Timpex
@import 'variables';
@import 'animations';
@import './gui/index';
@import './css/imports';

html {
    background-color: $background-color;
    width: 100%;
    margin: 0;
    // font-size: 14px;
    height: 100%;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
}

body {
    font-family: 'Roboto',sans-serif;
    background-color: $background-color;
    width: 100%;
    margin: 0;
    overflow-y: hidden;
    overflow-x: hidden;
    min-height: 100%;
}

#main {
    overflow: hidden;
    padding-bottom: 1rem;
}
